// Include Bootstrap
import * as bootstrap from "bootstrap";

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(() => {
	NProgress.inc();
}, 1000);
window.addEventListener("load", () => {
	NProgress.done();
	clearInterval(interval);
});

/******************************** Global functions *************************************/

// Dom ready function
window.domReady = function (callback) {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
};

// Get cookie function
window.getCookie = function (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "undefined";
};

// Set cookie function
window.setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	return cvalue;
};

// Get json data
window.getJsonData = function (id, clear) {
	var $el = document.getElementById(id);
	if ($el) {
		var json = $el.textContent || $el.innerText;
		var data = JSON.parse(json);
		if (clear) {
			$el.innerHTML = "";
		}
		return data;
	} else {
		return undefined;
	}
};

// Decode html
window.decodeHtml = function (html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
};

/******************************** Theme functions *************************************/

// Remove model project stylesheets
const $stylesheets = document.querySelectorAll("link");
if ($stylesheets) {
	let badIds = [
		"ian-directory-print",
		"ian-directory-custom",
		"ian-directory",
	];
	$stylesheets.forEach((el) => {
		if (badIds.includes(el.id)) {
			el.disabled = true;
			el.parentNode.removeChild(el);
		}
	});
}

// Back to top
const $backToTop = document.getElementById("back-to-top");
if ($backToTop) {
	$backToTop.addEventListener("click", (e) => {
		e.preventDefault();
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
	window.addEventListener("scroll", () => {
		if (window.scrollY >= 400) {
			$backToTop.classList.remove("d-none");
			$backToTop.classList.add("d-block");
		} else {
			$backToTop.classList.remove("d-block");
			$backToTop.classList.add("d-none");
		}
	});
}

/******************************** App specific functions *************************************/

// Print friendly change
/* Added directly to the app
const $printLink = document.querySelectorAll("li a.print");
if ($printLink) {
	$printLink.forEach((el) => {
		el.parentNode.style.display = "inline";
		el.innerText = "Print Page";
		el.title = "Print Page";
		el.addEventListener("click", (e) => {
			e.preventDefault();
			window.print();
		});
	});
}*/

// Search form buttons, inputs
const $homeForm = document.querySelector("#directory-home-form");
if ($homeForm) {
	$homeForm.querySelectorAll("input[type='image']").forEach((el) => {
		el.removeAttribute("src");
		el.type = "submit";
		el.value = "GO";
		el.classList.remove("go");
		el.classList.add("btn", "btn-sm", "btn-vermilion");
	});
	$homeForm.querySelector("#zipcode").placeholder = "Enter a zip code";
}
